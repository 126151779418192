const descriptionComp = () => import("../description/index.vue");
export default {
    name: "hero",
    components: { descriptionComp },
    props: [],
    data() {
        return {
            value: "bitcoin",
            size: 300,
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
